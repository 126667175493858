export function setHost(host) {
  return {
    type: "SET_HOST",
    payload: host,
  };
}

export function setAccessRights(accessRights, menus) {
  return {
    type: "SET_ACCESS_RIGHTS",
    payload: {
      accessRights,
      menus,
    },
  };
}

export function setUserData(userData) {
  return {
    type: "SET_USER_DATA",
    payload: userData,
  };
}

export function setConfigLoaded(isLoaded) {
  return {
    type: "SET_CONFIG_LOADED",
    payload: isLoaded,
  };
}

export function setRemoteConfig(newConfigs) {
  return {
    type: "SET_REMOTE_CONFIG",
    payload: newConfigs,
  };
}

export function setCustomUI(UISettings) {
  return {
    type:"SET_CUSTOM_UI",
    payload:UISettings
  }
}
