import React, { Component } from 'react'
// import Button from 'components/CustomButtons/Button.jsx'
import {withStyles} from "@material-ui/core"
import ReportDownloadButton from "framework/components/ReportDownloadButton"


//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'


class CashBankMutationReportAction extends Component {
	constructor(props){
		super(props)
		this.state = {
			isLoading 		: false,
			popup 			: false,
			action 			: ""
		}
	}

	handleColumnChange = (columns) => {
		if (this.props.onColumnChange) {
			this.props.onColumnChange(columns)
		}
	}
	
	render() {
		// const {classes} = this.props
		let buttons = []
		buttons.push(<ReportDownloadButton
			record={this.props.record}
			model={this.props.model}
		/>)
		return (
			<React.Fragment>
				{buttons}
			</React.Fragment>
		)
	}
}

const styles = {
	navigation:{
		display: 'flex',
		alignItems: 'center',
		marginLeft:"10px",
		flexGrow: "1",
		justifyContent: 'flex-end',
		"&>.navButton":{
			padding:"5px"
		},
		"&>.pageInfo":{
			display: 'flex',
			margin:"0px 5px",
			textAlign:"center",
			justifyContent: 'center',
			whiteSpace:"nowrap"
		}
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CashBankMutationReportAction))
