import Cookies from "js-cookie";
import Config from "../../company/Config";

const Session = (
  state = {
    ...(!Config.saasConfig?Config:{}),
    host: !Config.debug
      ? Config.host
      : Cookies.get("debugHost")
        ? Cookies.get("debugHost")
        : Config.host,
    token: Cookies.get("accessToken"),
    menus: ["hr.Employee"],
    accessRights: [],
    isConfigLoaded: false,
    companyLogo: null,
    userData: null,
    customUI:{}
  },
  action,
) => {
  switch (action.type) {
    case "SET_HOST":
      state = {
        ...state,
        host: action.payload,
      };
      break;
    case "SET_ACCESS_RIGHTS":
      state = {
        ...state,
        accessRights: action.payload.accessRights,
        menus: action.payload.menus,
        accessRightsLoaded: true,
      };
      break;
    case "SET_USER_DATA": {
      state = {
        ...state,
        userData: action.payload,
      };
      break;
    }
    case "SET_REMOTE_CONFIG": {
      let newState = {
        ...state,
        ...action.payload,
        isConfigLoaded: true,
      };
      if (Config.debug && Cookies.get("debugHost")) {
        newState.host = Cookies.get("debugHost");
      }
      state = newState;
      break;
    }
    case "SET_CUSTOM_UI":{
      state = {
        ...state,
        customUI:action.payload || {}
      }
      break;
    }
    default:
      break;
  }
  return state;
};

export default Session;
