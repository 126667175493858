import React, { Component } from "react";
import LazyContainer from "framework/containers/LazyContainer";
import Cookies from "js-cookie";
import { tl } from "framework/utils/Translator";
import { withRouter } from "react-router-dom";
import axios from "axios"

//redux
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "framework/utils/Redux";

//material-UI

import AdaptiveBreadcrumb from "framework/components/AdaptiveBreadcrumb";
import { CircularProgress } from "@material-ui/core";
import Config from "company/Config";

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:props.menu?true:false
    }
    this.props.empty({
      viewType: "activity",
    });
    this.props.push(null, {
      name: tl(Cookies.get("lang"), this.props.name),
      viewType: "activity",
      mode: "read",
      record: {},
      component: this.props.RenderedComponent,
      actions: this.props.formActions,
    });
  }
  
  componentDidMount(){
    if (this.props.menu) {
      let host = Config.saasConfig ? Config.saasHost : this.props.Session.host
      axios.get(host+"/api/custom/"+this.props.menu).then((response)=>{
        this.props.setCustomUI(response.data)
      }).catch((error)=>{
        console.error("[FRAMEWORK] : failed to load custom UI settings", error)
        this.props.setCustomUI(null)
      }).finally(()=>{
        this.setState({
          isLoading:false
        })
      })
    } else {
      this.props.setCustomUI(null)
    }
  }

  handleUpdate = (payload) => {
    this.handleBack(payload);
  };

  handleBack = (payload) => {
    if (this.props.match.params.id) {
      let location = this.props.location.pathname;
      let currentLocation = location.split("/");
      this.props.history.push(
        "/" + currentLocation[1] + "/" + currentLocation[2],
      );
    }
    this.props.back(payload);
  };

  render() {
    return (
      <React.Fragment>
        <div className="breadcrumb-container">
          <AdaptiveBreadcrumb
            View={this.props.View}
            onBack={this.handleBack}
          />
        </div>
        {this.state.isLoading?<div style={{width:'100%', height:"calc(100% - 40px)", display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
          <CircularProgress/>
        </div>:<>
        {this.props.View.activityStack.length > 0 ? (
          <LazyContainer
            match={this.props.match ? this.props.match : null}
            payload={
              this.props.View.activityStack[
                this.props.View.activityStack.length - 1
              ]
            }
            context={this.props.context}
          />
        ) : (
          ""
        )}
        </>}
        
        
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activity),
);
