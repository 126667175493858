import React, { Component } from 'react'
import Activity from "framework/containers/Activity"

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class FinanceARSimplePaymentActivity extends Component {
    constructor(props){
        super(props)
        this.RenderedComponent = import("app/components/Accounting/Voucher/VoucherSimple")
    }

    render() {
        return (
            <Activity 
                name="FinanceAPPayment"
                RenderedComponent={this.RenderedComponent}
                context="FinanceAPPayment"
            />
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FinanceARSimplePaymentActivity)
