import React, { Component } from "react";
import {InputBase, InputLabel, ButtonBase} from "@material-ui/core";
import { tl } from "framework/utils/Translator";
import Cookies from "js-cookie";
import ReportIcon from "@material-ui/icons/Report";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import CheckIcon from "@material-ui/icons/CheckCircle"
import Tooltip from "@material-ui/core/Tooltip";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import {isNumeric} from 'framework/utils/NumericChecker'
import _ from "lodash";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default class Text extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      field: props.field,
      value: props.value ? props.value : "",
      highlightAllOnFocus: true,
      _error: "",
      copySuccess:false
    };
    this.delayedCallback = _.debounce(this.applyChange, 500);

    if (this.props.renderMode === "gridRenderer") {
      let currentRowIndex = props.rowIndex;
      if (
        typeof props.cellErrors === "object" &&
        props.cellErrors !== null &&
        currentRowIndex in props.cellErrors
      ) {
        if (props.field in props.cellErrors[currentRowIndex]) {
          let errorText = _.get(props.cellErrors[currentRowIndex], props.field);
          if (props.value !== "" && errorText === "cannot be blank") {
            this.state._error = "";
          } else {
            this.state._error = tl(Cookies.get("lang"), errorText);
          }
        }
      }
    }

    if (this.props.renderMode === "gridEditor") {
      if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
        this.state.value = "";
      } else if (props.charPress) {
        this.state.value = props.charPress;
        this.state.highlightAllOnFocus = false;
      } else {
        this.state.value = props.value;
      }
    }

    this.localRef = React.createRef();
  }

  applyChange = (value) => {
    if (this.props.renderMode === "browserFilter") {
      this.props.onChange(value ? value : "");
    } else {
      this.props.onChange(this.props.field, value ? value : "");
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value ? nextProps.value : "" });
  }

  handleChange = (event) => {
    let { value } = event.target ? event.target : "";
    this.setState({ value: value });
    event.persist();
    this.delayedCallback(value);
  };

  componentDidMount() {
    if (this.props.renderMode === "gridEditor") {
      this.textInput.current.addEventListener("keydown", this.onKeyDown);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  //use param argument to access grid state (i.e. afterGuiAttached(param) {})
  afterGuiAttached() {
    if (this.props.renderMode === "gridEditor") {
      if (this.textInput) {
        this.textInput.current.focus();
        if (this.state.highlightAllOnFocus) {
          this.textInput.current.select();
        }
      }
    }
  }

  getValue() {
    return this.textInput.current.value ? this.textInput.current.value : "";
  }

  isPopup() {
    return false;
  }

  handleEnterPressed = (e) => {
    if (e.keyCode === 13) {
      if (this.props.onEnterPressed && this.props.renderMode !== "formView") {
        this.props.onEnterPressed(this.state.value);
      }
    }
  };

  triggerFocus = () => {
    console.log("try to focus");
    if (this.localRef.current) {
      this.localRef.current.focus();
    }
  };

  triggerBlur = () => {
    if (this.localRef.current) {
      this.localRef.current.blur();
    }
  };

  handleCopy = () => {
    this.setState({
      copySuccess:true
    })
    setTimeout(()=>{
      this.setState({
        copySuccess:false
      })
    }, 1000)
  }

  render() {
    let labelText = null;
    if (this.props.label) {
      labelText = this.props.label;
    } else {
      labelText = tl(Cookies.get("lang"), this.props.field);
    }

    let InputComponent = null;
    if (
      ["formCreate", "formEdit", "formView"].includes(this.props.renderMode)
    ) {
      InputComponent = (
        <div>
          {!this.props.disableLabel && (
            <InputLabel
              shrink
              className={`input-label ${this.props.white ? "white" : ""}`}
            >
              {labelText}
              {this.props.required ? "*" : ""}
            </InputLabel>
          )}
          <div
            className={`basic-input-container ${
              this.props.renderMode !== "formView" &&
              this.props.editable === false &&
              this.props.washedOff !== false &&
              "input-disabled"
            }`}
          >
            {this.props.useNative ? (
              <input
                value={this.state.value}
                ref={this.props.innerRef}
                disabled={
                  this.props.renderMode === "formView" ||
                  this.props.editable === false ||
                  this.props.washedOff === false
                }
                placeholder={
                  this.props.placeholder &&
                  this.props.editable !== false &&
                  this.props.washedOff !== false
                    ? this.props.placeholder
                    : ""
                }
                autoComplete={this.props.autoComplete || "false"}
                type={
                  this.props.type &&
                  ["tel", "password", "email"].includes(this.props.type)
                    ? this.props.type
                    : "text"
                }
                className={
                  this.props.renderMode === "formView" &&
                  (this.state.value.startsWith("http://") ||
                    this.state.value.startsWith("https://"))
                    ? "basic-inner-input-base-link"
                    : "basic-inner-input-base"
                }
                onChange={(event) => this.handleChange(event)}
                onClick={() => {
                  if (this.props.renderMode === "formView") {
                    if (
                      this.state.value.startsWith("http://") ||
                      this.state.value.startsWith("https://")
                    ) {
                      window.open(this.state.value, "_newtab");
                    }
                  }
                }}
                style={{ border: "none", padding: "0px 5px" }}
                {...(this.props.skipFocus ? { tabIndex: "-1" } : {})}
                {...(this.props.onEnterPressed
                  ? {
                      onKeyDown: this.handleEnterPressed,
                    }
                  : {})}
                {...(this.props.maxLength
                  ? { maxLength: this.props.maxLength }
                  : {})}
              />
            ) : (
              <InputBase
                value={this.state.value}
                ref={
                  this.props.innerRef
                    ? (ref) => this.props.innerRef(ref)
                    : () => {}
                }
                autoComplete={this.props.autoComplete || "off"}
                disabled={
                  this.props.renderMode === "formView" ||
                  this.props.editable === false ||
                  this.props.washedOff === false
                }
                placeholder={
                  this.props.placeholder &&
                  this.props.editable !== false &&
                  this.props.washedOff !== false
                    ? this.props.placeholder
                    : ""
                }
                fullWidth={true}
                id={this.props.field}
                onChange={(event) => this.handleChange(event)}
                onClick={() => {
                  if (this.props.renderMode === "formView") {
                    if (
                      this.state.value.startsWith("http://") ||
                      this.state.value.startsWith("https://")
                    ) {
                      window.open(this.state.value, "_newtab");
                    }
                  }
                }}
                className="basic-input-base"
                inputProps={{
                  autocomplete: this.props.autoComplete || "false",
                  type:
                    this.props.type &&
                    ["tel", "password", "email"].includes(this.props.type)
                      ? this.props.type
                      : "text",
                  ref: this.localRef,
                  className:
                    this.props.renderMode === "formView" &&
                    (this.state.value.startsWith("http://") ||
                      this.state.value.startsWith("https://"))
                      ? "basic-inner-input-base-link"
                      : "basic-inner-input-base",
                  ...(this.props.skipFocus ? { tabIndex: "-1" } : {}),
                }}
                {...(this.props.onEnterPressed
                  ? {
                      onKeyDown: this.handleEnterPressed,
                    }
                  : {})}
                {...(this.props.maxLength
                  ? { maxLength: this.props.maxLength }
                  : {})}
              />
            )}
            {(this.props.enableCopy && this.props.renderMode === "formView") && <Tooltip
              title={tl(this.state.copySuccess?"copy.success":"copyToClipboard")}
            >
              <CopyToClipboard
                onCopy={this.handleCopy}
                text={this.state.value}
              >
                <ButtonBase style={{padding:'10px'}}>
                  {React.createElement(this.state.copySuccess?CheckIcon:CopyIcon, {
                    className:"basic-input-icon",
                    style:this.state.copySuccess?{color:"4caf50"}:null
                  })}
                </ButtonBase>
              </CopyToClipboard>
              
            </Tooltip>}
            <Tooltip
              style={{ display: this.props.error ? "inline-block" : "none" }}
              title={this.props.error ? this.props.error : ""}
            >
              <ReportIcon className="basic-input-error-icon" />
            </Tooltip>
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridEditor") {
      InputComponent = (
        <div>
          <div className="basic-input-container">
            <input
              type="text"
              defaultValue={this.state.value}
              ref={this.textInput}
            />
          </div>
        </div>
      );
    } else if (this.props.renderMode === "gridRenderer") {
      let value = this.props.value ? this.props.value : ""
      if (this.props.getValueFrom) {
        value = _.get(this.props.data, this.props.getValueFrom)
      }
      InputComponent = (
        <div
          className={`grid-renderer-container ${
            this.props.washedOff === true && "input-disabled"
          }`}
        >
          <div
            className={`grid-renderer-content ${
              this.props.value === null && this.props.required === true
                ? "value-null"
                : ""
            }`}
          >
            {value}
          </div>
          <Tooltip
            style={{ display: this.state._error ? "inline-block" : "none" }}
            title={this.state._error ? this.state._error : ""}
          >
            <ReportIcon className="basic-grid-error-icon" />
          </Tooltip>
        </div>
      );
    } else if (this.props.renderMode === "browserFilter") {
      InputComponent = (
        <div>
          <div className="basic-input-container">
            <input
              type="text"
              defaultValue={this.props.value}
              onChange={(event) => this.handleChange(event)}
              className="grid-text-input"
            />
          </div>
        </div>
      );
    }
    return InputComponent;
  }
}
