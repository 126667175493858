import React, { Component } from 'react'
import Activity from "framework/containers/Activity"
import FormAction from 'app/modules/Accounting/reports/OutstandingReport/OutstandingReportAction'

//redux
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from 'framework/utils/Redux'

class OutstandingReceivableReportActivity extends Component {

	constructor(props){
		super(props)
		this.RenderedComponent = import("app/modules/Accounting/reports/OutstandingReport/OutstandingReport")
	}

	render() {
		return (
			<Activity 
				name="OutstandingReceivableReport"
				RenderedComponent={this.RenderedComponent}
				context="receivable"
				action="OutstandingReceivableReport"
				formActions={FormAction}
            />
		)
	}
}

export default connect(mapStateToProps,mapDispatchToProps)(OutstandingReceivableReportActivity)
