import {
  push,
  back,
  backFrom,
  update,
  empty,
  setNotification,
  setFormEdited,
  setUnsavedConfirm,
  setUnsavedConfirmClose,
  pop,
  setFormUnedited,
} from "framework/actions/View";

import {
  setAccessRights,
  setHost,
  setConfigLoaded,
  setRemoteConfig,
  setUserData,
  setCustomUI,
} from "framework/actions/Session";

export const mapStateToProps = (state) => {
  return {
    View: state.View,
    Session: state.Session,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    push: (currentState, payload) => {
      dispatch(push(currentState, payload));
    },
    back: (payload) => {
      dispatch(back(payload));
    },
    backFrom: (payload) => {
      dispatch(backFrom(payload));
    },
    update: (payload) => {
      dispatch(update(payload));
    },
    empty: (payload) => {
      dispatch(empty(payload));
    },
    pop : (payload) => {
      dispatch(pop(payload))
    },
    setNotification: (isOpen, message, color) => {
      dispatch(setNotification(isOpen, message, color));
    },
    setAccessRights: (accessRights, menus) => {
      dispatch(setAccessRights(accessRights, menus));
    },
    setHost: (host) => {
      dispatch(setHost(host));
    },
    setConfigLoaded: (isLoaded) => {
      dispatch(setConfigLoaded(isLoaded));
    },
    setUserData: (userData) => {
      dispatch(setUserData(userData));
    },
    setRemoteConfig: (config) => {
      dispatch(setRemoteConfig(config));
    },
    setFormEdited: (isEdited) => {
      dispatch(setFormEdited(isEdited))
    },
    setFormUnedited: (isEdited) => {
      dispatch(setFormUnedited(isEdited))
    },
    setUnsavedConfirm: (payload) => {
      dispatch(setUnsavedConfirm(payload))
    },
    setUnsavedConfirmClose:()=>{
      dispatch(setUnsavedConfirmClose())
    },
    setCustomUI:(UISettings)=>{
      dispatch(setCustomUI(UISettings))
    }
  };
};
